<template>
  <el-button :disabled="disabled" :type="type" :size="size" :plain="plain" :loading="loading" @click="open">{{ displayedTitle }} </el-button>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { ConfirmMixin } from './confirm-mixin';

@Component({
  name: 'ConfirmButton',
  mixins: [ConfirmMixin],
  props: {
    header: String,
    target: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: String,
    type: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: 'small'
    },
    plain: Boolean,
    loading: Boolean
  }
})
export default class extends Vue {}
</script>
